<template>
  <span>
    <b-btn
      variant="none"
      class="btn btn-sm btn-none p-0"
      @click="getHistorico()"
    >
      <font-awesome-icon
        icon="fa-solid fa-list"
        class="text-primary"
      />
    </b-btn>

    <b-modal
      ref="modalHistorico"
      size="lg"
      ok-only
    >
      <div slot="modal-title">
        <b-row>
          <b-col>
            <h4>Histórico de alteração do plano - {{ empresa }}</h4>
          </b-col>
        </b-row>
        <fieldset class="font-weight-bold text-muted small">
          Histórico de alterações do plano empresarial
        </fieldset>
      </div>

      <tabela-repeticao
        v-model="valores"
        :somente-visualizacao="true"
        :colunas="colunas"
      >
        <template #visualizacao="{slotScope: {valor}}">
          {{ valor }}
        </template>
      </tabela-repeticao>
    </b-modal>
  </span>
</template>

<script>

export default {
  components: {
    TabelaRepeticao: () => import('@pilar/components/tabela-repeticao/TabelaRepeticao.vue'),
  },
  props: {
    empresa: {
      type: String,
      required: true,
    },
    tipoHistorico: {
      type: Number,
      required: true,
    },
    buscarHistorico: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      valores: [],
      colunas: [
        { key: 'data', label: 'Data' },
        { key: 'acao', label: 'Ação' },
        { key: 'usuario', label: 'Usuário' },
      ],

    }
  },
  watch: {

  },
  methods: {
    openModal() {
      this.$refs.modalHistorico.show()
    },
    closeModal() {
      this.$refs.modalHistorico.hide()
    },
    getHistorico() {
      this.buscarHistorico(this.tipoHistorico)
        .then(payload => {
          this.valores = payload.data
          this.openModal()
        })
        .catch(err => {
          this.closeModal()
          this.$erro(err)
        })
    },
  },
}
</script>
